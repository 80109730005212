var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mapContainer"},[_c('label',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-8"},[_c('gmap-autocomplete',{ref:"gmapAutocomplete",staticClass:"form-control",attrs:{"placeholder":"Buscar ubicación","options":{
      componentRestrictions: {
        country: [
          'pa' ],
      },
    }},on:{"place_changed":_vm.setPlace}})],1),_c('div',{staticClass:"col-md-2 text-right"},[_c('button',{staticClass:"btn btn-primary",staticStyle:{"width":"max-content"},attrs:{"type":"button"},on:{"click":function($event){return _vm.updateMarker()}}},[_vm._v("Actualizar Marcador ")])])])]),_c('br'),_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"center":_vm.center,"zoom":13,"map-type-id":"roadmap","options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }}},_vm._l((_vm.markers),function(m,index){return _c('GmapMarker',{key:index,attrs:{"position":m.position,"clickable":true,"draggable":true},on:{"dragend":_vm.updateCoordinates}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }