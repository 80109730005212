<template>
  <form v-on:submit.prevent="save(digital)">
    <div class="col p-20">
      <div class="form-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Redes Sociales</label>
              <div class="demo-checkbox">
                <input type="checkbox" id="socTwitter" class="filled-in chk-col-light-blue" v-model="digital.twitter.active">
                <label for="socTwitter">Twitter</label>

                <input type="checkbox" id="socInstagram" class="filled-in chk-col-light-blue" v-model="digital.instagram.active">
                <label for="socInstagram">Instagram</label>

                <input type="checkbox" id="socFacebook" class="filled-in chk-col-light-blue" v-model="digital.facebook.active">
                <label for="socFacebook">Facebook</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="demo-checkbox">
                <input type="checkbox" id="send" class="filled-in chk-col-light-blue" v-model="digital.webNote.active">
                <label for="send">Subir nota a pagina web</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="demo-checkbox">
                <input type="checkbox" id="title" class="filled-in chk-col-light-blue" checked
                       v-model="digital.videoTitle.active">
                <label for="title">Enviar Titulo y Descripción de Video</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="demo-checkbox">
                <input type="checkbox" id="bite" class="filled-in chk-col-light-blue" checked
                       v-model="digital.webNoteText.active">
                <label for="bite">Enviar Avances de Nota Web</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-actions text-right">
              <button type="submit" class="btn btn-info text-right">
                <span v-if="!isLoading">Guardar</span>
                <span v-if="isLoading">Guardando...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'Digital.vue',
  props: {
    digital: Object,
    id: String,
    user: Object
  },
  data: () => {
    return {
      isLoading: false
    };
  },
  methods: {
    async save(digital) {
      try {

        if (!this.user.profile.role.canUpdate) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });

          return;
        }
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        const db = firebase.firestore();
        await db.collection('tasks').doc(this.id).set({ updatedAt, digital }, { merge: true });
        this.$toasted.show('Guardado',
          {
            duration: 800,
            className: 'toastClass'
          });

        this.isLoading = false;

      } catch (error) {
        // console.error('error: ', error);
        this.isLoading = false;
        alert('Error al guardar');
      }
    }
  }
};
</script>

<style scoped>

</style>
