<template>
  <form v-on:submit.prevent="save(sports)">
    <div class="col p-20">
      <div class="form-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Subir Nota a Redes</label>
              <div class="demo-checkbox">
                <input type="checkbox" id="soc-twitter" class="filled-in chk-col-light-blue" v-model="sports.tw">
                <label for="soc-twitter">Twitter</label>

                <input type="checkbox" id="soc-instagram" class="filled-in chk-col-light-blue" v-model="sports.ig">
                <label for="soc-instagram">Instagram</label>

                <input type="checkbox" id="soc-facebook" class="filled-in chk-col-light-blue" v-model="sports.fb">
                <label for="soc-facebook">Facebook</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="demo-checkbox">
                <input type="checkbox" id="webSend" class="filled-in chk-col-light-blue" v-model="sports.webNote">
                <label for="webSend">Subir nota a pagina web</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="demo-checkbox">
                <input type="checkbox" id="titleSend" class="filled-in chk-col-light-blue" checked
                       v-model="sports.videoTitle">
                <label for="titleSend">Enviar Titulo y Descripción de Video</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <div class="demo-checkbox">
                <input type="checkbox" id="biteSend" class="filled-in chk-col-light-blue" checked
                       v-model="sports.webNoteText">
                <label for="biteSend">Enviar Avances de Nota Web</label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-actions text-right">
              <button type="submit" class="btn btn-info text-right">
                <span v-if="!isLoading">Guardar</span>
                <span v-if="isLoading">Guardando...</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'SportAxis',
  props: {
    stages: Array,
    sports: Object,
    id: String,
    user: Object
  },
  data: () => {
    return {
      isLoading: false
    }
  },
  methods: {
    async save(sports) {
      try {

        if (!this.user.profile.role.canUpdateSports) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });

          return;
        }
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        const db = firebase.firestore();
        const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        await db.collection('tasks').doc(this.id).set({ sports, updatedAt }, { merge: true });
        this.$toasted.show('Guardado',
          {
            duration: 800,
            className: 'toastClass'
          });

        this.isLoading = false;

      } catch (error) {
        console.error("error: ", error);
        this.isLoading = false;
        alert('Error al guardar');
      }
    }
  }
};
</script>

<style scoped>

</style>
