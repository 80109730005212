import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home';
import Edit from '../views/Edit';
import IdeasNew from '../views/ideasnew';
import Login from '../views/Login';
import AssignmentsList from "@/views/AssignmentsList";
import AssignmentsHistory from "@/views/AssignmentsHistory";
import AssignmentsListGlobal from "@/views/AssignmentsListGlobal";
import AssignmentsHistoryGlobal from "@/views/AssignmentsHistoryGlobal";
import Details from "@/views/Details";
import DetailsGlobal from "@/views/DetailsGlobal";
import Calendar from "@/views/Calendar";


Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresAuth: false }
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/idea/:id',
    name: 'idea',
    component: Edit,
    meta: { requiresAuth: true }
  },
  {
    path: '/ideasnew',
    name: 'ideasnew',
    component: IdeasNew,
    meta: { requiresAuth: true }
  },
  {
    path: '/assignmentsList',
    name: 'assignmentsList',
    component: AssignmentsList,
    meta: { requiresAuth: true }
  },
  {
    path: '/assignmentsHistory',
    name: 'assignmentsHistory',
    component: AssignmentsHistory,
    meta: { requiresAuth: true }
  },
  {
    path: '/assignmentsHistoryGlobal',
    name: 'assignmentsHistoryGlobal',
    component: AssignmentsHistoryGlobal,
    meta: { requiresAuth: true }
  },
  {
    path: '/assignmentsListGlobal',
    name: 'assignmentsListGlobal',
    component: AssignmentsListGlobal,
    meta: { requiresAuth: true }
  },
  {
    path: '/details/:id',
    name: 'details',
    component: Details,
    meta: { requiresAuth: true }
  },
  {
    path: '/detailsGlobal/:id/:type',
    name: 'detailsGlobal',
    component: DetailsGlobal,
    meta: { requiresAuth: true }
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: Calendar,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'About',
    meta: { requiresAuth: false },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
