<template>
  <div class="page-wrapper seccionContainer">
    <div class="container-fluid m-t-15">
      <h2 class="m-b-20">Agregar Nueva Incepción</h2>
      <div class="card">
        <div class="card-body">
          <div class="form-body">
            <h3 class="box-title">Generales</h3>
            <hr>
            <form v-on:submit.prevent="save(task)">
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label class="control-label">Título</label>
                    <input type="text" class="form-control tvnInputTitle" v-model="task.title" required />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="control-label">Departamento</label>
                    <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="task.department"
                            required>
                      <option></option>
                      <option :value="department" v-for="department in departments" :key="department.id">
                        {{ department.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="control-label">Prioridad</label>
                    <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="task.priority"
                            required>
                      <option></option>
                      <option :value="priority" v-for="priority in priorities" :key="priority.id">
                        {{ priority.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label class="control-label">Agendar:</label>
                    <input type="date" class="form-control tvnInputTitle" placeholder="dd/mm/yyyy"
                           v-model="task.schedule" required>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label class="control-label">Categoría</label>
                    <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="task.category"
                            required>
                      <option></option>
                      <option :value="category" v-for="category in categories" :key="category.id">
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Enfoque Noticioso</label>
                    <textarea rows="2" class="form-control tvnInputTextArea" v-model="task.description"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Etiquetas (Metadata)</label>
                    <tags-input v-model="task.tags"
                                discard-search-text=""
                                value-fields="value"
                                :add-tags-on-comma="true"
                                :typeahead="true"
                                placeholder="Agregar etiquetas utilizando commas"
                    ></tags-input>
                  </div>
                </div>
              </div>

              <div class="form-actions text-right">
                <button type="button" class="btn btn-outline btn-dark m-r-40" @click="clearTask">Borrar</button>
                <button type="submit" class="btn btn-info text-right">
                  <span v-if="!isLoading"> Agregar Nueva </span>
                  <span v-if="isLoading"> Guardando... </span>
                </button>
              </div>
            </form>
          </div>

        </div>
      </div>
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import store from '../store';
import firebase from 'firebase';
import dayjs from 'dayjs';
import Footer from '@/components/Footer';
import VoerroTagsInput from '@voerro/vue-tagsinput';

export default {
  name: 'ideas.vue',
  components: {
    Footer,
    'tags-input': VoerroTagsInput
  },
  data: () => {
    return {
      id: '',
      isLoading: false,
      question: {
        email: '',
        phone: '',
        question: '',
        source: '',
        createdAt: null
      },
      task: {
        category: null,
        description: '',
        digital: {
          facebook: {
            active: false,
            executed: false,
            executedAt: null
          },
          instagram: {
            active: false,
            executed: false,
            executedAt: null
          },
          twitter: {
            active: false,
            executed: false,
            executedAt: null
          },
          videoTitle: {
            active: false,
            executed: false,
            executedAt: null
          },
          webNote: {
            active: false,
            executed: false,
            executedAt: null
          },
          webNoteText: {
            active: false,
            executed: false,
            executedAt: null
          }
        },
        formats: [],
        production: {
          graphics: {
            active: false,
            executed: false,
            executedAt: null
          },
          images: {
            active: false,
            executed: false,
            executedAt: null
          },
          ots: {
            active: false,
            executed: false,
            executedAt: null
          },
          type: {
            active: false,
            label: '',
            executed: false,
            executedAt: null
          }
        },
        questions: [],
        priority: null,
        schedule: new Date(),
        title: ''
      }
    };
  },
  methods: {
    clearQuestion() {
      this.question = {
        email: '',
        phone: '',
        question: '',
        source: ''
      };
    },
    clearTask() {
      this.id = '';
      this.task = {
        category: null,
        description: '',
        priority: null,
        schedule: new Date(),
        title: ''
      };
    },
    getDayTitle(date) {
      const createdAt = dayjs(date);
      const today = dayjs(new Date());
      const days = today.diff(createdAt, 'day');

      if (days === 1) {
        return 'Hace 1 día';
      } else if (days > 1) {
        return `Hace ${days} días`;
      } else {
        return 'Hoy';
      }
    },
    async addQuestion(question) {
      try {

        if (!this.isLoading) {

          this.isLoading = true;
          const db = firebase.firestore();
          this.task.questions.push({ ...question, createdAt: new Date() });

          if (this.id) {
            await db.collection('tasks').doc(this.id).set(this.task, { merge: true });
            alert('Guardado');
          }

          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        alert('Error al guardar');
      }
    },
    async save(task) {
      try {

        if (this.isLoading) {
          return;
        }

        if (this.user.profile.role.canCreate ||
          (this.task.department.id === 'sports' && this.user.profile.role.id === 'sports')) {
          this.isLoading = true;
          const db = firebase.firestore();
          this.id = dayjs(new Date()).format('YYMMDDhhmmss');

          task.stage = this.stages[0];

          if (this.task.tags && this.task.tags.length) {
            task.tags = this.task.tags.map(tag => tag.value);
          }
          task.createdAt = new Date();
          task.updatedAt = task.createdAt;
          task.createdBy = { ...this.user.profile, id: this.user.uid };
          task.schedule = dayjs(task.schedule).toDate();

          const taskRef = db.collection('tasks').doc(this.id);
          await taskRef.set({ ...task });

          this.isLoading = false;
          this.task = task;
          await this.$router.push({ name: 'home' });
        } else {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });
        }

      } catch (error) {
        console.error(error);
        this.isLoading = false;
        alert('Error al guardar');
      }
    }
  },
  computed: {
    stages() {
      return store.state.catalogs.stages.sort((a, b) => (a.order > b.order) ? 1 : -1);
    },
    categories() {
      return store.state.catalogs.categories;
    },
    departments() {
      return store.state.catalogs.department;
    },
    priorities() {
      return store.state.catalogs.priorities;
    },
    user() {
      return store.state.user;
    }
  }
};
</script>

<style scoped>
.tvnInputTitle {
  border: solid 1px #5ea2d940;
  border-bottom: 2px solid #1e88e5;

}

.tvnInputTitle:focus {
  box-shadow: 0 3px #c6d9f7;
  font-weight: bold;
}

.tvnInputTextArea {
  border: solid 1px #5ea2d940;
  border-bottom: 2px solid #1e88e5;
  font-size: .8rem;

}

.tvnInputTextArea:focus {

  box-shadow: 0 3px #c6d9f7;
  font-size: 1rem;
}
</style>
