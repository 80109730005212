<template>
  <div class="page-wrapper seccionContainer">
    <div class="container-fluid m-t-15">
      <div class="row">
        <div class="col-6 p-l-5">
          <h2 class="m-b-20">Calendario de Asignaciones</h2>
        </div>
        <div class="col-6 text-right">
          <router-link to="/AssignmentsListGlobal">
            <button class="btn btn-warning btn-outline"> Completadas</button>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <div class="card">
            <div class="card-body">
              <h5 class="text-dark p-b-10">{{selectedDay}}</h5>
              <div v-for="(stage, index) in stages" :key="index">
                <template v-if="days[selectedDay] && days[selectedDay][stage.id]">

                  <div v-for="(task, index2) in days[selectedDay][stage.id].tasks" :key="index2">

                    <h5><i class="fa fa-circle" :class="task.stage.color"></i> {{ stage.title }}</h5>
                    <ul class="list-group list-group-flush">
                      <router-link :to="{path: 'idea/'+task._id}" class="hoveBG">
                        <li class="list-group-item">
                          <p class="textOver">{{ task.title }}</p>
                        </li>
                      </router-link>
                    </ul>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-9">
          <div class="card card-body">
            <FullCalendar :options="calendarOptions" />
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import firebase from 'firebase';
import dayjs from 'dayjs';
import store from '@/store';

export default {
  name: 'Calendar',
  components: {
    FullCalendar
  },
  data() {
    return {
      days: {},
      loading: false,
      selectedDay: dayjs().format('YYYY-MM-DD'),
      calendarOptions: {
        dateClick: this.handleDateClick,
        locale: esLocale,
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        themeSystem: 'bootstrap',
        events: []
      }
    };
  },
  computed: {
    stages() {
      return store.state.catalogs.stages.sort((a, b) => (a.order > b.order) ? 1 : -1);
    }
  },
  methods: {
    handleDateClick(arg) {
      this.selectedDay = arg.dateStr;
    },
    loadTasks() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }

      this.unsubscribe = firebase.firestore().collection('tasks')
        .orderBy('schedule', 'desc')
        .onSnapshot((snapshot) => {

          this.calendarOptions.events = [];
          this.days = {};
          snapshot.forEach(doc => {

            const task = doc.data();
            const dayFormat = dayjs(task.schedule.toDate()).format('YYYY-MM-DD');
            if (this.days[dayFormat]) {
              if (this.days[dayFormat][task.stage.id]) {
                this.days[dayFormat][task.stage.id].count++;
                this.days[dayFormat][task.stage.id].tasks.push({ ...task, _id: doc.id });
              } else {
                this.days[dayFormat][task.stage.id] = {
                  count: 1,
                  title: task.stage.title,
                  tasks: [{ ...task, _id: doc.id }]
                };
              }
            } else {
              this.days[dayFormat] = {
                [task.stage.id]: {
                  count: 1,
                  title: task.stage.title,
                  tasks: [{ ...task, _id: doc.id }]
                }
              };
            }

            this.days[dayFormat][task.stage.id]['eventColor'] = task.stage.eventColor;
          });

          console.log('this.days: ', this.days);
          for (const day of Object.keys(this.days)) {
            for (const stage of Object.keys(this.days[day])) {

              this.calendarOptions.events.push({
                title: `${this.days[day][stage].count} ${this.days[day][stage].title}`,
                date: day,
                textColor: 'white',
                backgroundColor: this.days[day][stage].eventColor,
                className: 'eventClassBox'
              });
            }
          }


          this.loading = false;
        });
    }
  },
  mounted() {
    this.loading = true;
    this.loadTasks();
  }
};
</script>

<style>
.textOver {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

a {
  color: #262626 !important;

}

.fc-daygrid-day-frame:hover {
  background-color: rgb(218, 233, 248);
  cursor: pointer;
}

.eventClassBox {
  font-size: 13px !important;
  font-weight: 700 !important;
  margin: 5px !important;
  border-radius: 5px !important;
  padding-left: 12px !important;
}

th .fc-scrollgrid-sync-inner {
  text-transform: capitalize;
  font-weight: 700;
  background-color: #c6ddef;
  border: solid 1px white;
  padding: 3px;
  font-size: 16px;
}

.fc-col-header-cell-cushion {

}

.fc-event-main {
  text-align: left !important;
}

.list-group-item {
  border: none !important;
}

.hoveBG li:hover {
  background-color: rgb(249, 249, 249) !important;
}
</style>
