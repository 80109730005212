<template>
  <div class="page-wrapper seccionContainer">
    <div class="container-fluid m-t-15">
      <h2 class="m-b-20 text-dark">Historial</h2>

      <div class="row">
        <div class="col-12">
          <div class="card ">
            <div class="card-header">
              <div class="row">
                <div class="col-md-4">
                  <h4 class="card-title">Historial de Asignaciones </h4>
                  <h6 class="card-subtitle">Listado de asignaciones</h6>
                </div>

                <div class="col-md-4">
                  <div class="input-group mb-3">
                    <input type="text" class="form-control tvnInputTitle" placeholder="Palabras Claves" aria-label="Palabras Claves" v-model="tagFilter">
                    <div class="input-group-append">
                      <button class="btn btn-outline-secondary" type="button" @click="loadTaskByTag">Buscar</button>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 text-right">
                  <span class="form-control m-r-5 cursorPointer" @click="loadAllTasks">Todas</span>
                  <router-link to="assignmentsList">
                    <span class="form-control m-r-5 cursorPointer">Asignaciones</span>
                  </router-link>
                </div>
              </div>

            </div>
            <div class="card-body">
              <div class="table-responsive table-striped">
                <table class="table">
                  <thead>
                  <tr>
                    <th width="15%">Programada</th>
                    <th width="10%">Dept.</th>
                    <th width="10%">Prioridad</th>
                    <th width="10%">Categoría</th>
                    <th width="40%">Asignación</th>
                    <th width="15%">Estado</th>
                    <th width="10%">Para</th>
                  </tr>
                  </thead>
                  <tbody>
                  <router-link v-for="task in tasks" :key="task.id" :to="{path: 'details/'+task.id}" tag="tr">
                    <td class="font-weight-bold"><strong>{{ task.schedule | date('MMM DD, YYYY') }}</strong></td>
                    <td>{{ task.department.label }}</td>
                    <td class="text-danger">{{ task.priority.name }}</td>
                    <td>
                      <h4 class="badge badge-warning">{{ task.category.name }}</h4>
                    </td>
                    <td><span class=""><i class="fa fa-clock-o"></i>{{ task.title }}</span>
                    </td>
                    <td>
                      <div class="font-weight-bold"><i class="fa fa-circle text-success"></i> {{ task.stage.title }}
                      </div>
                    </td>
                    <td>
                      <span v-if="task.operative">
                        {{ task.operative.journalist.name + ' ' + task.operative.journalist.lastName }}
                      </span>
                    </td>
                  </router-link>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import store from '@/store';

export default {
  computed: {
    user() {
      return store.state.user;
    }
  },
  data: () => {
    return {
      loading: false,
      tagFilter: '',
      tasks: [],
      unsubscribe: null
    };
  },
  name: 'AssignmentsHistory.vue',
  methods: {
    loadMyTasks() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.user && this.user.uid) {
        this.unsubscribe = firebase.firestore().collection('tasks')
          .where('operative.journalist.id', '==', this.user.uid)
          .orderBy('schedule', 'desc')
          .onSnapshot((snapshot) => {
            this.tasks = [];
            if (!snapshot.empty) {
              snapshot.forEach(doc => this.tasks.push({ ...doc.data(), id: doc.id }));
            }
            this.loading = false;
          });
      }
    },
    loadAllTasks() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.user && this.user.uid) {
        this.unsubscribe = firebase.firestore().collection('tasks')
          .orderBy('schedule', 'desc')
          .onSnapshot((snapshot) => {
            this.tasks = [];
            if (!snapshot.empty) {
              snapshot.forEach(doc => this.tasks.push({ ...doc.data(), id: doc.id }));
            }
            this.loading = false;
          });
      }
    },
    loadTaskByTag() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.user && this.user.uid && this.tagFilter) {
        this.unsubscribe = firebase.firestore().collection('tasks')
          .where('tags', 'array-contains', this.tagFilter)
          .orderBy('schedule', 'desc')
          .onSnapshot((snapshot) => {
            this.tasks = [];
            if (!snapshot.empty) {
              snapshot.forEach(doc => this.tasks.push({ ...doc.data(), id: doc.id }));
            }
            this.loading = false;
          });
      }
    }
  },
  mounted() {
    this.loadMyTasks();
  },
  watch: {
    user() {
      this.loadMyTasks();
    }
  }
};
</script>

<style scoped>

</style>
