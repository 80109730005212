<template>
  <div class='page-wrapper seccionContainer'>
    <div class='container-fluid m-t-15'>
      <div class='row'>
        <div class='col-6 p-l-5'>
          <h2 class='m-b-20'>Repositorio de Contenidos</h2>
        </div>
        <div class='col-6 text-right'>
          <router-link to='/AssignmentsListGlobal'>
            <button class='btn btn-warning btn-outline m-r-10'> Completadas</button>
          </router-link>
          <router-link to='/calendar'>
            <button class='btn btn-warning btn-outline'><i class='fa fa-calendar-alt'></i> Ver Calendario</button>
          </router-link>
        </div>
      </div>

      <div class='row'>
        <div class='col-lg-12' v-if='(!stagesU || !stagesU.length)'>
          <div class='card'>
            <div class='card-body'>
              <h4 class='card-title'>
                <span v-if='!loading'>Algo salio mal intentalo mas tarde...</span>
                <span v-if='loading'>Un momento...</span>
              </h4>
            </div>
          </div>
        </div>

        <template v-if='stagesU && stagesU.length'>
          <div class='col-lg-4' v-for='stage in stagesU' :key='stage._id'>
            <div class='card'>
              <div class='card-body'>
                <h4 class='card-title'><i class='fa fa-circle' :class='stage.color'></i> {{ stage.title }}</h4>
                <h6 class='card-subtitle'>{{ stage.subTitle }}</h6>
                <hr class=''>
              </div>

              <router-link :to='{path: stage.route}' v-if='stage.showAdd'>
                <button type='button' class='btn btn-info btn-circle cratebtn' title='Crear Nueva Incepcion'><i
                  class='fa fa-plus'></i></button>
              </router-link>

              <div class='comment-widgets' v-for='task in stage.tasks' :key='task._id'>
                <router-link :to="{path: 'idea/'+task._id}">
                  <div class='d-flex flex-row comment-row'>
                    <div class='p-2' v-if='stage.showAvatar && task.createdBy'>
                    <span class='round'>

                      <img v-if='task.createdBy.profilePic'
                           v-image-fall-back
                           width='50'
                           :src='task.createdBy.profilePic'
                      />
                      <img v-else
                           width='50'
                           src='../assets/images/avatar-fallback.png'
                      />
                    </span>
                    </div>
                    <div class='comment-text w-100'>
                      <template v-if='stage.showAssigned'>
                        <h6 class='text-info' v-if='task.createdBy'>
                          {{ task.createdBy.displayName }}
                        </h6>
                        <span class=' m-b-10 small' v-if='stage.showAssigned && task.updatedAt'>
                          Modificado: {{ task.updatedAt | date }}
                        </span>
                        <br />
                        <br>
                      </template>
                      <template v-else>
                        <h6 class='text-megna'>
                          <i class='ti-check'></i> {{ task.updatedAt | date }}
                        </h6>
                      </template>

                      <p class='m-b-5 taskHomeDescription'><i class='far fa-star text-danger'
                                                              v-if='task.priority.id == 1'></i> {{ task.title }}</p>

                      <span class=' m-b-10 small' v-if='task.operative && task.operative.journalist'>
                          Periodista: <b>{{ task.operative.journalist.completeName }}</b>
                      </span>

                      <div class='comment-footer'>
                        <span class='m-b-10 m-r-5 p-2' v-if='task.priority === 1'><i class='ti-star'></i></span>
                        <span class='label label-warning m-b-10 p-2 text-capitalize'>{{ task.category.name }}</span>
                        <span class='label label-info m-b-10 m-lg-2 p-2'>{{ task.schedule | date('MMM DD, YYYY')
                          }}</span>
                        <span class='action-icons' v-if='stage.showAvatar'>
                        <a href='javascript:void(0)' v-if='task.comments && task.comments.length' title='Comentarios'>
                          <i class='ti-comment-alt'></i> {{ task.comments.length }}
                        </a>
                      </span>
                      </div>
                    </div>
                  </div>
                </router-link>
                <hr class='taskSeparators'>
              </div>

            </div>
          </div>
        </template>
      </div>
      <Footer class='footerContent'></Footer>
    </div>


  </div>
</template>

<script>
import firebase from 'firebase';
import store from '@/store';
import Footer from '@/components/Footer';

export default {
  components: {
    Footer
  },
  computed: {
    stages() {
      return store.state.catalogs.stages.sort((a, b) => (a.order > b.order) ? 1 : -1);
    }
  },
  data: () => {
    return {
      stagesU: [],
      unsubscribe: null,
      loading: false,
      role: store.state.user
    };
  },
  name: 'Home',
  methods: {
    loadTasks() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }

      this.unsubscribe = firebase.firestore().collection('tasks')
        .orderBy('updatedAt', 'desc')
        .onSnapshot((snapshot) => {
          const stages = [];

          this.stages.forEach(stage => {
            const s = {
              ...stage,
              tasks: []
            }

            stages.push(s)
          })

          snapshot.docs.forEach((doc) => {

            const tmp = { ...doc.data(), _id: doc.id };
            tmp.createdBy = tmp.createdBy || { avatar: '../assets/images/users/3.jpg' };
            tmp.createdBy.displayName = tmp.createdBy.displayName || (tmp.createdBy.name + ' ' + tmp.createdBy.lastName);
            const stageIndex = stages.findIndex(stage => stage.id === tmp.stage.id);

            if (stages[stageIndex].tasks) {
              stages[stageIndex].tasks.push(tmp);
            } else {
              stages[stageIndex].tasks = [tmp];
            }

          });

          this.stagesU = stages.filter(stage => stage.showInBoard);
          this.loading = false;
        });
    }
  },
  watch: {
    stages() {
      this.loadTasks();
    }
  },
  mounted() {
    this.loading = true;
    if (this.stages.length) {
      this.loadTasks();
    }
  }
};
</script>

<style scoped>
a {
  color: #000000;
}

.taskSeparators {
  margin: 0 !important;
}

.cratebtn {
  position: absolute;
  right: 16px;
  top: 24px;
}

.comment-widgets {
  max-height: 1020px;
  overflow: hidden;
  overflow-y: auto;
}

.executed:hover {
  border-left: 3px solid #ff0000 !important;
}

.executed-bk {
  background-color: #fdfdfd;
  border-radius: 3px;
  box-shadow: #0000000f 3px 5px 3px;
}

.footerContent {
  font-size: 14px;
  color: #3c3c3c;
  padding-bottom: 10px;
  margin-bottom: 0;
  width: inherit;
  margin-left: auto;
  margin-right: auto;
}
</style>
