<template>
  <div class="page-wrapper seccionContainer" v-if="task">
    <div class="container-fluid m-t-15">
      <h2 class="m-b-20 text-dark">Detalle de Asignación</h2>
      <div class="row">
        <div class="col-12">
          <div class="card ">
            <div class="card-header">
              <div class="row">
                <div class="col-md-8 p-l-20">
                  Para: {{ task.schedule | date('MMM DD, YYYY') }}
                </div>
                <div class="col-md-4 text-right">
                  <div class="cursorPointer" v-on:click="backButton"><i class="fa fa-arrow-left"></i> Regresar</div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-6 p-3">
                  <button class="btn btn-success" @click="setOnSite(task)">En Sitio</button>
                  <br>
                  <span class="small" v-if="task.onSiteAt">{{ task.onSiteAt.toDate() | date('MMM DD, YYYY, HH:mm A')
                    }}</span>
                </div>
                <div class="col-6b- p-3">
                  <button class="btn btn-success" @click="setCoverCompleted(task)">Cobertura Completada</button>
                  <br>
                  <span class="small"
                        v-if="task.coverageCompletedAt">{{ task.coverageCompletedAt.toDate() | date('MMM DD, YYYY, HH:mm A')
                    }}</span>
                </div>
              </div>
              <div class="resaltadoContainer">
                <br>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label class="text-info">Lugar:</label>
                    <h4> {{ task.operative.place }}</h4>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label class="text-info">Fecha de Cobertura:</label>
                    <h4> {{ task.schedule | date('MMM DD, YYYY') }}</h4>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label class="text-info">Hora de Cobertura:</label>
                    <h4> {{ task.operative.date | date('HH:mm A') }}</h4>
                  </div>
                  <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                    <label class="text-info">Formato Solicitado:</label>
                    <h4 v-for="(format, index) in task.operative.formats" :key="format.id">
                      <span style="margin-right: 10px;" v-if="format.requested"
                            :for="format.code + '-' + index">{{ task.operative.formats[index].formato }},&nbsp;</span>
                    </h4>
                  </div>
                </div>
              </div>
              <div class="row p-20" v-if="task">
                <div class="col-12">
                  <h2 class="strong detailsTitle">{{ task.title }}</h2>
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-3" v-if="task.department">
                  <label class="control-label d-block text-info">Departamento:</label>
                  <p class="">{{ task.department.label }}</p>
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-3" v-if="task.priority">
                  <label class="control-label  d-block text-info">Prioridad:</label>
                  <p class="">{{ task.priority.name }}</p>
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-3" v-if="task.category">
                  <label class="control-label d-block text-info">Categoría:</label>
                  <p class="">{{ task.category.name }}</p>
                </div>
                <div class="col-6 col-sm-6 col-md-4 col-lg-3">
                  <label class="control-label d-block  text-info">Asignada Para:</label>
                  <p class="">{{ task.schedule | date('MMM DD, YYYY') }}</p>
                </div>
                <div class="col-12" v-if="task.description">
                  <label class="control-label small d-block  text-info">Enfoque Noticioso:</label>
                  <p>{{ task.description }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col-12 p-20">
                  <h4 class="">Personal</h4>
                  <div class="row p-10" v-if="task.operative">
                    <template v-if="task.operative.journalist">
                      <div class="col-6 col-sm-6 col-md-4 col-lg-3" v-if="task.operative.journalist.name">
                        <label class="control-label d-block text-info">Periodista</label>
                        <span
                          class="questionsClass">{{
                            task.operative.journalist.name
                          }} {{ task.operative.journalist.lastName }}</span>
                      </div>
                      <div class="col-6 col-sm-6 col-md-4 col-lg-3" v-if="task.operative.cameraman">
                        <label class="control-label d-block text-info">Camarógrafo</label>
                        <span>{{ task.operative.cameraman.name }} {{ task.operative.cameraman.lastName }}</span>
                      </div>
                      <div class="col-6 col-sm-6 col-md-4 col-lg-3" v-if="task.operative.orderly.entrada">
                        <label class="control-label d-block text-info">Turno</label>
                        <span>{{ task.operative.orderly.entrada }} - {{ task.operative.orderly.salida }}</span>
                      </div>
                      <div class="col-6 col-sm-6 col-md-4 col-lg-3" v-if="task.operative.date">
                        <label class="control-label d-block text-info">Hora de Cobertura</label>
                        <span>{{ task.operative.date | date('HH:mm A') }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="row" v-if="task.questions && task.questions.length">
                <div class="col-12">
                  <h4 class="card-title">Preguntas</h4>
                  <div class="list-group">
                    <span
                      class="list-group-item list-group-item-action flex-column align-items-start questionContainer mb-1"
                      v-for="(question,index) in task.questions" :key="index">
                      <div class="d-flex justify-content-between">
                        <h5 class="mb-1 questionsClass">{{ question.question }}</h5>
                      </div>
                      <div class="row mb-1">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="question.source">
                           Fuente: {{ question.source }}
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="!question.source">
                           --
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="question.phone">
                          Teléfono: {{ question.phone }}
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="!question.phone">
                          --
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="question.email">
                          Email: {{ question.email }}
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3" v-if="!question.email">
                          --
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                          <button type="button" v-if="question.completed" class="btn  btn-success"
                                  @click="patchQuestions(task,index)">
                              <i class="fas fa-check-circle"></i> Ejecutada
                          </button>
                          <button type="button" v-if="!question.completed" class="btn  btn-secondary"
                                  @click="patchQuestions(task,index)">
                              <i class="far fa-window-close"></i> No Ejecutada
                          </button>
                          <span v-if="isLoading">
                            ...
                          </span>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row" v-if="task.operative">
                <div class="col-12 p-20">
                  <h4 class="">Eje Operativo</h4>
                  <div class="row p-10">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-4" v-if="task.operative.place">
                      <label class="control-label d-block text-info">Detalles del Lugar</label>
                      <span>{{ task.operative.place }}</span>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-6" v-if="task.operative.directions">
                      <label class="control-label d-block text-info">Dirección en Mapa</label>
                      <span>{{ task.operative.directions.address }}</span>
                    </div>
                    <div class="col-12 col-sm-6 col-md-4 col-lg-2"
                         v-if="task.operative.directions && task.operative.directions.coordinates">
                      <a target="_blank"
                         :href="'http://www.google.com/maps/place/'+task.operative.directions.coordinates.lat+','+task.operative.directions.coordinates.lng">
                        <button class="btn btn-info m-t-20"><i class="fa fa-map-marker"></i> Ver en Mapa</button>
                      </a>
                    </div>
                  </div>
                  <div class="row p-10">
                    <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                      <label class="control-label d-block  text-info">Equipo:</label>
                      <div class="d-inline" v-for="(equipments, index) in task.operative.equipment" :key="index">
                        <span class="form-control m-r-5">{{ equipments.value }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row p-10">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label class="control-label d-block  text-info">Vehículo:</label>
                      <span v-if="task.operative.car">{{ task.operative.car.auto }}</span>
                    </div>
                  </div>
                  <div class="row p-10">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label class="control-label d-block  text-info">Romper Programación:</label>
                      <span v-if="task.operative.cut === true">Si</span>
                      <span v-if="task.operative.cut === false">No</span>
                    </div>
                  </div>
                  <div class="row p-10">
                    <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                      <label class="control-label d-block  text-info">Información Adicional:</label>
                      <span class="">{{ task.operative.additionalInformation }}</span>
                    </div>
                  </div>
                </div>
                <hr />
              </div>
              <div class="row p-20" v-if="task.operative && task.operative.formats && task.operative.formats.length">
                <div class="col-12">
                  <h4 class="py-2">Formato</h4>
                  <div class="row p-10">

                    <div class="col-12 col-sm-4" v-for="(format, index) in task.operative.formats" :key="format.id">
                      <div
                        :class="{'executed-task':format.executed, 'not-executed-task':!format.executed, 'solicitada':format.requested}">
                        <input type="checkbox" :id="format.code + '-' + index" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="format.executed" @change="patchFormat(task, index)" />
                        <label :for="format.code + '-' + index">{{ format.formato }}</label>
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row p-20" v-if="task.digital">
                <div class="col-12">
                  <h4 class="py-2">Digital</h4>
                  <div class="row p-10">
                    <div class="demo-checkbox">
                      <div v-if="task.digital.twitter.active"
                           :class="{'executed-task':task.digital.twitter.executed, 'not-executed-task':!task.digital.twitter.executed}">
                        <input type="checkbox" id="twExec" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="task.digital.twitter.executed" @change="patchDigital(task, 'twitter')" />
                        <label for="twExec">Twitter</label>

                        <br />
                      </div>

                      <div v-if="task.digital.instagram.active"
                           :class="{'executed-task':task.digital.instagram.executed, 'not-executed-task':!task.digital.instagram.executed}">
                        <input type="checkbox" id="igExec" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="task.digital.instagram.executed" @change="patchDigital(task, 'instagram')" />
                        <label for="igExec">Instagram</label>

                        <br />
                      </div>

                      <div v-if="task.digital.facebook.active"
                           :class="{'executed-task':task.digital.facebook.executed, 'not-executed-task':!task.digital.facebook.executed}">
                        <input type="checkbox" id="fbExec" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="task.digital.facebook.executed" @change="patchDigital(task, 'facebook')">
                        <label for="fbExec">Facebook</label>

                        <br />
                      </div>

                      <div v-if="task.digital.webNote.active"
                           :class="{'executed-task':task.digital.webNote.executed, 'not-executed-task':!task.digital.webNote.executed}">
                        <input type="checkbox" id="noteExec" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="task.digital.webNote.active" @change="patchDigital(task, 'webNote')">
                        <label for="noteExec">Nota Web Ejecutada</label>

                        <br />
                      </div>

                      <div v-if="task.digital.videoTitle.active"
                           :class="{'executed-task':task.digital.videoTitle.executed, 'not-executed-task':!task.digital.videoTitle.executed}">
                        <input type="checkbox" id="titleExec" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="task.digital.videoTitle.executed" @change="patchDigital(task, 'videoTitle')">
                        <label for="titleExec">Titulo y Descripción del Video</label>

                        <br />
                      </div>

                      <div v-if="task.digital.webNoteText.active"
                           :class="{'executed-task':task.digital.webNoteText.executed, 'not-executed-task':!task.digital.webNoteText.executed}">
                        <input type="checkbox" id="biteExec" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="task.digital.webNoteText.executed" @change="patchDigital(task, 'webNoteText')">
                        <label for="biteExec">Avances</label>
                      </div>
                    </div>
                  </div>

                  <hr />
                </div>
              </div>
              <div class="row p-20" v-if="task.production">
                <div class="col-12">
                  <h4 class="pt-2">Producción</h4>
                  <div class="row p-10">
                    <div class="demo-checkbox">
                      <div v-if="task.production.criteria">
                        <label>
                          Criterios:
                          <b>{{ task.production.criteria }}</b>
                        </label>
                      </div>

                      <div v-if="task.production.type.active"
                           :class="{'executed-task':task.production.type.executed, 'not-executed-task':!task.production.type.executed}">
                        <input type="checkbox" id="typeExec" class="filled-in chk-col-light-blue"
                               v-model="task.production.type.executed"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               @change="patchProduction(task, 'type')" />
                        <label for="typeExec">{{ task.production.type.label }}</label>
                        <br />
                      </div>
                      <div v-if="task.production.graphics.active"
                           :class="{'executed-task':task.production.graphics.executed, 'not-executed-task':!task.production.graphics.executed}">
                        <input type="checkbox" id="graphicsExec" class="filled-in chk-col-light-blue"
                               v-model="task.production.graphics.executed"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               @change="patchProduction(task, 'graphics')" />
                        <label for="graphicsExec">Gráficos</label>
                        <br />
                      </div>
                      <div v-if="task.production.ots.active"
                           :class="{'executed-task':task.production.ots.executed, 'not-executed-task':!task.production.ots.executed}">
                        <input type="checkbox" id="otsExec" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="task.production.ots.executed" @change="patchProduction(task,'ots')">
                        <label for="otsExec">OTS</label>
                        <br />
                      </div>
                      <div v-if="task.production.images.active"
                           :class="{'executed-task':task.production.images.executed, 'not-executed-task':!task.production.images.executed}">
                        <input type="checkbox" id="imageExec" class="filled-in chk-col-light-blue"
                               :disabled="isLoading || task.stage.id === 'completed'"
                               v-model="task.production.images.executed" @change="patchProduction(task,'images')">
                        <label for="imageExec">Imagenes</label>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="col-md-8 col-12 col-sm-12" v-if="task.production">
                <div class="form-group">
                  <h4 class="pt-2">Guía del Periodista</h4>
                  <textarea v-model="task.production.journalistGuide" class="form-control" rows="4"
                            placeholder="Ingrese la guía aquí..."></textarea>
                  <div class="text-right mt-2">
                    <button class="btn btn-success btn-outline btn-large" v-if="task.production.journalistGuide"
                            @click="updateGuide(task)">Actualizar Guía
                    </button>
                    <button class="btn btn-success btn-outline btn-large" v-if="!task.production.journalistGuide"
                            @click="updateGuide(task)">Agregar Guía
                    </button>
                  </div>
                </div>
              </div>
              <div class="row m-t-10 p-20" v-if="task.stage" v-show="task.stage.id !== 'completed'">
                <div class="col-12 col-sm-4">
                  <button class="btn btn-info btn-lg btn-block" @click="complete(task)">Tarea Completada</button>
                </div>
                <div class="col">

                </div>
              </div>

            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import firebase from 'firebase';
import store from '@/store';

export default {
  name: 'Details',
  components: {
    Footer
  },
  computed: {
    formats() {
      return store.state.catalogs.formats;
    },
    user() {
      return store.state.user;
    },
    stages() {
      return store.state.catalogs.stages.sort((a, b) => (a.order > b.order) ? 1 : -1);
    }
  },
  data: () => {
    return {
      comment: '',
      currentAttach: null,
      id: '',
      isLoading: false,
      question: {
        email: '',
        phone: '',
        question: '',
        source: '',
        createdAt: null
      },
      searchInput: '',
      searchResults: [],
      task: {
        category: '',
        description: '',
        priority: '',
        schedule: new Date(),
        title: '',
        operative: {
          directions: {
            address: ''
          },
          coordinates: {
            lat: null,
            lng: null
          }

        }
      },
      cAnimation: true,
      unsubscribe: null
    };
  },
  methods: {
    getTask() {

      if (this.unsubscribe) {
        this.unsubscribe();
      }

      try {
        const db = firebase.firestore();

        this.unsubscribe = db.collection('tasks').doc(this.$route.params.id).onSnapshot((taskRef) => {
          this.task = taskRef.data();
          const container = this.$el.querySelector('#chat-container');

          if (container) {
            container.scrollTop = container.scrollHeight;
          }

          if(this.task && this.task.operative && this.task.operative.formats && this.task.operative.formats.length && this.formats) {
            this.task.operative.formats = this.formats.map(format => {
              const fd = this.task.operative.formats.find(f => f.code === format.code)

              return {
                ...format,
                ...fd
              }
            });
          } else {
            this.task.operative.format = this.formats;
          }

          if (this.task && !this.task.viewed) {
            db.collection('tasks').doc(this.$route.params.id).set({ viewed: true }, { merge: true });
          }

        });
      } catch (error) {
        alert('No fue posible cargar la página');
      }
    },
    async patchQuestions(task, index) {
      task.questions[index].completed = !this.task.questions[index].completed;
      await this.save(task);
    },
    async patchFormat(task, index) {
      task.operative.formats[index].executedAt = task.operative.formats[index].executed ? new Date() : null;
      const resp = await this.save(task);

      if (!resp) {
        this.task.operative.formats[index].executed = !task.operative.formats[index].executed;
      }
    },
    async patchDigital(task, type) {
      task.digital[type].executedAt = task.digital[type].executed ? new Date() : null;
      const resp = await this.save(task);

      if (!resp) {
        this.task.digital[type].executed = !task.production[type].executed;
      }
    },
    async patchProduction(task, type) {
      task.production[type].executedAt = task.production[type].executed ? new Date() : null;
      const resp = await this.save(task);

      if (!resp) {
        this.task.production[type].executed = !task.production[type].executed;
      }
    },
    async save(task) {
      try {
        if (!this.user.profile.role.canCheckQuestion) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });

          return;
        }

        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        task.updatedBy = { ...this.user.profile, id: this.user.uid };

        const db = firebase.firestore();

        const taskRef = db.collection('tasks').doc(this.id);
        await taskRef.set({ ...task }, { merge: true });

        this.isLoading = false;
        this.task = task;
        this.$toasted.show('Guardado', {
          duration: 800,
          className: 'toastClass'
        });

        return true;
      } catch (error) {
        console.error('error: ', error);
        this.isLoading = false;
        this.$toasted.show('¡No fue posible guardar los datos!', {
          duration: 800,
          className: 'toastClass'
        });

        return false;
      }
    },
    async complete(task) {
      try {
        if (!this.user.profile.role.canComplete) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });
          return;
        }

        if (!confirm('¿Esta seguro de completar esta tarea?')) {
          return;
        }

        if (this.isLoading) {
          return;
        }
        this.isLoading = true;

        task.stage = this.stages.find(stage => stage.id === 'completed');

        const db = firebase.firestore();
        const taskRef = db.collection('tasks').doc(this.id);
        await taskRef.set({ stage: task.stage }, { merge: true });

        this.isLoading = false;
        this.task = task;
        this.$toasted.show('Tarea completada',
          {
            duration: 800,
            className: 'toastClass'
          });


      } catch (error) {
        console.error('error: ', error);
        this.isLoading = false;
        alert('Hubo un error al guardar');
      }
    },
    async updateGuide(task) {
      try {
        if (!this.user.profile.role.canComplete) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });
          return;
        }

        if (this.isLoading) {
          return;
        }
        this.isLoading = true;

        const db = firebase.firestore();
        const taskRef = db.collection('tasks').doc(this.id);
        await taskRef.set({
          production: {
            journalistGuide: task.production.journalistGuide
          }
        }, { merge: true });

        this.isLoading = false;
        this.task = task;
        this.$toasted.show('Tarea actualizada',
          {
            duration: 800,
            className: 'toastClass'
          });
      } catch (error) {
        console.error('error: ', error);
        this.isLoading = false;
        alert('Hubo un error al guardar');
      }
    },
    async setCoverCompleted() {
      try {

        if (this.isLoading) {
          return;
        }
        this.isLoading = true;
        const coverageCompletedAt = firebase.firestore.FieldValue.serverTimestamp();

        const db = firebase.firestore();
        const taskRef = db.collection('tasks').doc(this.id);
        await taskRef.set({ coverageCompletedAt }, { merge: true });

        this.isLoading = false;
        this.task.coverageCompletedAt = coverageCompletedAt;
        this.$toasted.show('Tarea actualizada',
          {
            duration: 800,
            className: 'toastClass'
          });
      } catch (error) {
        console.error('error: ', error);
        this.isLoading = false;
        alert('Hubo un error al guardar');
      }
    },
    async setOnSite() {
      try {

        if (this.isLoading) {
          return;
        }
        this.isLoading = true;
        const onSiteAt = firebase.firestore.FieldValue.serverTimestamp();

        const db = firebase.firestore();
        const taskRef = db.collection('tasks').doc(this.id);
        await taskRef.set({ onSiteAt }, { merge: true });

        this.isLoading = false;
        this.task.onSiteAt = onSiteAt;
        this.$toasted.show('Tarea actualizada',
          {
            duration: 800,
            className: 'toastClass'
          });
      } catch (error) {
        console.error('error: ', error);
        this.isLoading = false;
        alert('Hubo un error al guardar');
      }
    }
  },
  watch: {
    formats() {
      this.getTask();
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getTask();
  }
};
</script>

<style>
@import "../assets/css/details.css";


.questionsClass {
  font-weight: 600 !important;
}

.executed-task {
  background-color: #f6f7f7;
  padding: 12px 16px 5px 16px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid rgb(0, 166, 0);
}

.executed-task:after {
  content: 'Ejecutado';
  font-size: 12px;
}

.not-executed-task {
  background-color: #ffffff;
  padding: 12px 16px 5px 16px;
  margin-bottom: 20px;
  border-radius: 8px;
}

.not-executed-task:after {
  content: 'No Ha Sido Ejecutado';
  font-size: 12px;
}

.executed-task label, .not-executed-task label {
  margin-bottom: 0px !important;
}

.resaltadoContainer {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 10px;
  background: #c3f0ff;
  border-radius: 5px;
  border: solid 1px #1b89c4;
  box-shadow: 0 3px 3px gainsboro;
}
.solicitada{
  background-color: rgb(209, 233, 252);
}
</style>
