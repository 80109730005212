<template>
  <div class="page-wrapper seccionContainer">
    <div class="container-fluid m-t-15">
      <div class="row">
        <div class="col-6 p-l-5">
          <h2 class="m-b-20">Listado de Completadas</h2>
        </div>
        <div class="col-6 text-right">
          <router-link to="/calendar">
            <button class="btn btn-warning btn-outline"><i class="fa fa-calendar-alt"></i> Ver Calendario</button>
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card ">
            <div class="card-header">
              <div class="row">
                <div class="col-md-8 p-l-20">
                  <h4 class="card-title">Asignaciones Completadas </h4>
                </div>
                <div class="col-md-4 text-right">
                  <router-link to="assignmentsHistoryGlobal"><span class="form-control">Historial</span></router-link>
                </div>
              </div>

            </div>
            <div class="card-body">
              <div class="table-responsive table-striped">
                <table class="table">
                  <thead>
                  <tr>
                    <th width="15%">Programada</th>
                    <th width="10%">Dept.</th>
                    <th width="10%">Prioridad</th>
                    <th width="10%">Categoría</th>
                    <th width="40%">Asignación</th>
                    <th width="15%">Estado</th>
                    <th width="10%">Para</th>
                  </tr>
                  </thead>
                  <tbody>
                  <router-link v-for="task in tasks" :key="task.id"
                               :to="{path: 'detailsGlobal/'+task.id+'/tasks'}" tag="tr">
                    <td class="font-weight-bold"><strong>{{ task.schedule | date('MMM DD, YYYY') }}</strong></td>
                    <td>{{ task.department.label }}</td>
                    <td class="text-danger">{{ task.priority.name }}</td>
                    <td>
                      <h4 class="badge badge-warning">{{ task.category.name }}</h4>
                    </td>
                    <td><span class=""><i class="fa fa-clock-o"></i>{{ task.title }}</span>
                    </td>
                    <td>
                      <div class="font-weight-bold"><i class="fa fa-circle" :class="task.stage.color"></i>
                        {{ task.stage.title }}
                      </div>
                    </td>
                    <td>
                      <div v-if="task.operative">
                      {{ task.operative.journalist.name + ' ' + task.operative.journalist.lastName }}
                      </div>
                    </td>
                  </router-link>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer';
import firebase from 'firebase';
import store from '@/store';
import dayjs from 'dayjs';

export default {
  name: 'AssignmentsList',
  components: {
    Footer
  },
  computed: {
    user() {
      return store.state.user;
    }
  },
  data: () => {
    return {
      unsubscribe: null,
      loading: false,
      tasks: [],
      today: ''
    };
  },
  methods: {
    loadTasks() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }

        this.unsubscribe = firebase.firestore().collection('tasks')
            .where('stage.id', '==', 'completed')
          .orderBy('schedule', 'desc')
          .onSnapshot((snapshot) => {
            console.log(snapshot.empty);
            this.tasks = [];
            if (!snapshot.empty) {
              snapshot.forEach(doc => this.tasks.push({ ...doc.data(), id: doc.id }));
            }

            this.loading = false;
          });
    },
    loadAllTasks() {
      if (this.unsubscribe) {
        this.unsubscribe();
      }
      if (this.user && this.user.uid) {
        this.unsubscribe = firebase.firestore().collection('tasks')
          .where('schedule', '>=', this.today)
          .orderBy('schedule', 'desc')
          .onSnapshot((snapshot) => {
            this.tasks = [];
            if (!snapshot.empty) {
              snapshot.forEach(doc => this.tasks.push({ ...doc.data(), id: doc.id }));
            }
            this.loading = false;
          });
      }
    }
  },
  watch: {
    user() {
      this.loadTasks();
    }
  },
  mounted() {
    this.today = new Date(dayjs().format('YYYY-MM-DD'))
    this.loadTasks();

  }
};
</script>

<style scoped>
.selectView {
  background-color: #eaeaea;
  border: none;
  box-shadow: 0 3px 2px #e2e2e2;
}
</style>
