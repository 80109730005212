import Vue from 'vue';
import App from './App.vue';
import firebase from './firebase';
import ImageFallBack from './directives/images-fallback';
import dayjs from 'dayjs';
import router from './router';
import store from './store';
import VueRx from 'vue-rx'

import * as VueGoogleMaps from 'vue2-google-maps';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/custom.css';
import VueClipboard from 'vue-clipboard2';
import Toasted from 'vue-toasted';
import VueChatScroll from "vue-chat-scroll";
import { MSSubject } from './MSSubject';

MSSubject.sendMessage(localStorage.getItem('msToken'));

Vue.config.productionTip = false;
Vue.use(VueRx)
Vue.directive('image-fall-back', ImageFallBack);
Vue.filter('date', (value, format) => {

  try {
    if (value) {
      if (typeof value.toDate === 'function') {
        value = value.toDate();
      }
      return dayjs(String(value)).format(format || 'MMM DD, YYYY - hh:mm');
    }
  } catch (error) {
    console.error('date parser error: ', error);
  }
});
Vue.use(firebase);
Vue.use(Toasted, {iconPack : 'material'});
Vue.use(VueClipboard);
Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyAvdGcmDMbiAdgJhfzhLvz-uBh9An0-kEk',
      libraries: 'places'
    },
    installComponents: true,
  });
Vue.use(VueChatScroll)
Vue.mixin({
  methods: {
    backButton: function () {
      this.$router.go(-1)
    },
  },
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
