<template>
  <div id="app" class="vh-100">
    <Header v-if="this.$route.name !== 'login'" />
    <router-view />
  </div>
</template>
<script>

import Header from './components/Header.vue';
import { MSSubject } from './MSSubject';

export default {
  name: 'Home',
  components: {
    Header
  },
  created() {
    MSSubject.sendMessage(localStorage.getItem('msToken'));
  }
};
</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
