<template>
  <form v-on:submit.prevent="save(production)">
    <div class="row p-20">
      <div class="col-6">
        <div class="form-group">
          <label class="control-label">Tipo de Noticias</label>
          <select class="form-control" required @change="setType($event)">
            <option selected></option>
            <option value="Titular">Titular</option>
            <option value="Avance">Avance</option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label class="control-label">Material de Apoyo</label>

          <div class="demo-checkbox">
            <input type="checkbox" id="recursos_graficos" class="filled-in chk-col-light-blue"
                   v-model="production.graphics.active">
            <label for="recursos_graficos">Recursos Gráficos</label>

            <input type="checkbox" id="OTS" class="filled-in chk-col-light-blue" v-model="production.ots.active">
            <label for="OTS">OTS</label>

            <input type="checkbox" id="images" class="filled-in chk-col-light-blue" v-model="production.images.active">
            <label for="images">Images</label>
          </div>
        </div>
      </div>
      <div class="col-10">
        <div class="form-group">
          <label class="control-label">Criterios de Producción:</label>
          <input type="text" class="form-control" placeholder="" v-model="production.criteria" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-actions text-right">
          <button type="submit" class="btn btn-info text-right">
            <span v-if="!isLoading">Guardar</span>
            <span v-if="isLoading">Guardando...</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import firebase from 'firebase';

export default {
  name: 'Production.vue',
  props: {
    user: Object,
    production: Object,
    id: String
  },
  data: () => {
    return {
      isLoading: false
    };
  },
  methods: {
    setType(event) {
      this.production.type.active = !!event.target.value;
      this.production.type.label = event.target.value;
    },
    async save(production) {
      try {

        if (!this.user.profile.role.canUpdateProduction) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });

          return;
        }
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        const db = firebase.firestore();
        const updatedAt = firebase.firestore.FieldValue.serverTimestamp();
        await db.collection('tasks').doc(this.id).set({ production, updatedAt }, { merge: true });
        this.$toasted.show('Guardado',
          {
            duration: 800,
            className: 'toastClass'
          });

        this.isLoading = false;

      } catch (error) {
        console.error('error: ', error);
        this.isLoading = false;
        alert('Error al guardar');
      }
    }
  }
};
</script>

<style scoped>

</style>
