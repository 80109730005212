import Firebase from 'firebase';
import store from './store';
import router from './router';
import { combineLatest, distinctUntilKeyChanged, ReplaySubject, map, distinct } from 'rxjs';
import { MSSubject } from './MSSubject';

let db = null;
const config = {
  apiKey: 'AIzaSyD5STCGi8YXP6IhfBUMTiP9EcufEultA44',
  authDomain: 'wicon-mobile-development.firebaseapp.com',
  databaseURL: 'https://wicon-mobile-development.firebaseio.com',
  projectId: 'wicon-mobile-development',
  storageBucket: 'wicon-mobile-development.appspot.com',
  messagingSenderId: '514317850961',
  appId: '1:514317850961:web:98057036713670e10c5b70',
  measurementId: 'G-8S6NV8ZM3W'
};
const getCatalog = async () => {
  const catalogs = [];
  const snapshot = await db.collection('catalogs').get();

  snapshot.docs.forEach((doc) => {
    catalogs[doc.id] = doc.data().list;
  });

  const snapshotUsers = await db.collection('users')
    .where('role', 'in', ['journalist', 'cameraman'])
    .orderBy('name')
    .get();

  if (!snapshotUsers.empty) {
    catalogs['journalist'] = [];
    catalogs['cameraman'] = [];

    snapshotUsers.forEach(doc => {
      if (doc.data().first) {
        catalogs[doc.data().role].unshift({ ...doc.data(), id: doc.id });
      } else {
        catalogs[doc.data().role].push({ ...doc.data(), id: doc.id });
      }
    });
  }

  return catalogs;
};
const getProfile = async (roles, groups, user) => {
  let role = roles.find(role => (groups || []).includes(role.msId));
  let userData = { ...user }

  if(!role) {
    const userDoc = await db.collection('users').doc(user.uid).get();
    userData = userDoc.data() || { role: '' };
    role = roles.find(role => role.id === userData.role);
  }

  return { ...userData, role };
};
const processAuth = async (groups, user) => {
  const catalogs = await getCatalog();
  const profile = await getProfile(catalogs['roles'], groups, user.toJSON());

  store.commit('updateCatalogs', catalogs);
  store.commit('updateUser', {
    uid: user.uid,
    displayName: user.displayName,
    email: user.email,
    profile
  });

  return profile;
};
export default {
  install: (Vue) => {

    const firebase = Firebase.initializeApp(config);
    db = firebase.firestore();
    const auth = firebase.auth();
    const MSProvider = new Firebase.auth.OAuthProvider('microsoft.com');

    MSProvider.setCustomParameters({
      tenant: 'd8046389-6859-4530-a6de-89c8297333fe'
    });
    MSProvider.addScope('mail.read');
    MSProvider.addScope('user.read');
    MSProvider.addScope('user.read.all');
    MSProvider.addScope('user.readbasic.all');
    MSProvider.addScope('group.read.all');

    Vue.prototype.$auth = {
      login: (username, pass) => {
        return auth.signInWithEmailAndPassword(username, pass);
      },
      MSLogin: () => {
        return auth.signInWithPopup(MSProvider);
      },
      logout: async () => {
        await auth.signOut();
      }
    };

    const loggedInSubject = new ReplaySubject(1);
    auth.onAuthStateChanged(loggedInSubject);

    combineLatest([
      MSSubject.getMessage().pipe(distinct()),
      loggedInSubject.pipe(
        map(val => val || {}),
        distinctUntilKeyChanged('uid'))
    ])
      .subscribe(async (val) => {
        console.log('val: ', val);
        if (!val || !val.length || !val[1] || !val[1].uid) {
          if (router.currentRoute.name !== 'login') {
            router.replace('/login').then().catch();
          }
        } else {
          const profile = await processAuth(val[0], val[1]);

          if ((!router.currentRoute.name || router.currentRoute.name === 'login') && profile && profile.role) {
            await router.replace('/' + profile.role.homeLink);
          } else if (!profile || !profile.role) {
            auth.signOut();
            alert('Por motivos de seguridad, su sesión fue cerrada, vuelva a ingresar');
          }
        }

      });
  }
};
