<template>
  <div class="mapContainer">
    <label style="width: 100%;">
      <div class="row">
        <div class="col-md-8">
          <gmap-autocomplete ref="gmapAutocomplete" placeholder="Buscar ubicación" @place_changed="setPlace"
                             class="form-control" :options="{
        componentRestrictions: {
          country: [
            'pa',
          ],
        },
      }">
          </gmap-autocomplete>
        </div>
        <div class="col-md-2 text-right">
          <button type="button" class="btn btn-primary" style="width: max-content;" @click="updateMarker()">Actualizar
            Marcador
          </button>
        </div>
      </div>
    </label>
    <br />
    <GmapMap
      :center="center"
      :zoom="13"
      map-type-id="roadmap"
      style="width: 100%; height: 100%;"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false,
      }"
    >
      <GmapMarker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @dragend="updateCoordinates"
      />
    </GmapMap>

  </div>
</template>

<script>

import { gmapApi } from 'vue2-google-maps';

export default {
  computed: { google: gmapApi },
  props: {
    directions: Object
  },
  data: () => {
    return {
      addressText: '',
      coordinates: null,
      center: {
        lat: 9.032178,
        lng: -79.5208439
      },
      markers: [{
        position: {
          lat: 9.032178,
          lng: -79.5208439
        }
      }]
    };
  },
  methods: {
    addMarker(lat, lng) {
      if (lat && lng) {
        this.markers = [];
        const marker = { lat, lng };
        this.markers.push({ position: marker });
        this.center = { lat, lng };
      }

      this.currentPlace = null;
    },
    setPlace(place) {
      this.currentPlace = place;

      this.$emit('direction-change', {
        address: place.formatted_address,
        coordinates: {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        }
      });
    },
    updateCoordinates(location) {


      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      };

      this.geocoderService.geocode({
          location: {
            lat: location.latLng.lat(),
            lng: location.latLng.lng()
          }
        },
        (results, status) => {

          if (status === this.google.maps.GeocoderStatus.OK) {
            this.$refs.gmapAutocomplete.$refs.input.value = results[0].formatted_address;


            this.$emit('direction-change', {
              address: results[0].formatted_address,
              coordinates: {
                lat: location.latLng.lat(),
                lng: location.latLng.lng()
              }
            });
          }

        });
    },
    updateMarker() {
      if (this.currentPlace) {
        this.addMarker(this.currentPlace.geometry.location.lat(), this.currentPlace.geometry.location.lng());
      }
    }
  },
  mounted() {
    this.$gmapApiPromiseLazy({})
      .then(() => {
        this.geocoderService = new this.google.maps.Geocoder();

        if (this.directions && this.directions.coordinates) {
          this.addMarker(this.directions.coordinates.lat, this.directions.coordinates.lng);
          this.$refs.gmapAutocomplete.$refs.input.value = this.directions.address;
        }
      });
  }
};
</script>

<style>
.mapContainer {
  border-radius: 5px;
  height: 400px;
  padding-bottom: 80px;
}

.pac-target-input {
  display: inline;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

form .label {
  width: 100%;
}
</style>
