<template>
  <header class='topbar'>
    <div class='container-fluid'>
      <nav class='navbar top-navbar navbar-expand-md navbar-light'>
        <div class='navbar-header'>
          <router-link to='/home'>
            <img class='mainLogo' src='../assets/images/logoTvnNoticias.svg' alt='homepage' />
          </router-link>
        </div>
        <div class='navbar-collapse'>
          <ul class='navbar-nav mr-auto mt-md-0'>
          </ul>
          <ul class='navbar-nav my-lg-0'>
            <li class='nav-item dropdown'>
              <a class='nav-link dropdown-toggle text-muted waves-effect waves-dark' href='' data-toggle='dropdown'
                 aria-haspopup='true' aria-expanded='false'>
                <img
                  v-if='user.profile.profilePic'
                  v-image-fall-back
                  class='profile-pic'
                  width='50'
                  :src='user.profile.profilePic'
                />
                <img v-else
                     class='profile-pic'
                     src='../assets/images/avatar-fallback.png'
                />
              </a>
              <div class='dropdown-menu dropdown-menu-right scale-up'>
                <ul class='dropdown-user'>
                  <li>
                    <div class='dw-user-box'>
                      <div class='u-img'>
                        <img v-if='user.profile.profilePic'
                          v-image-fall-back
                          :src='user.profile.profilePic'
                        />
                        <img v-else
                          src='../assets/images/avatar-fallback.png'
                        />
                      </div>
                      <div class='u-text'>
                        <h4>{{ user.profile.displayName || user.profile.name }} {{ user.profile.lastName }}</h4>
                        <p class='text-muted'>{{ user.email }}</p>
                        <p class='text-muted' v-if='user.profile.role'>{{user.profile.role.id}}</p>
                      </div>
                    </div>
                  </li>
                  <li role='separator' class='divider'></li>
                  <li><a href='#'><i class='ti-user'></i> Perfil</a></li>
                  <li role='separator' class='divider'></li>
                  <li @click='logOut()'><a href='#'><i class='fa fa-power-off'></i> Cerrar Sessión</a></li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import store from '@/store';

export default {
  name: 'Header.vue',
  computed: {
    user() {
      return store.state.user;
    }
  },
  methods: {
    logOut() {
      this.$auth.logout();
    }
  }
};
</script>

<style scoped>

</style>
