<template>
<div class="">Developed by <a href="http://wicon.io" title="Ir a Wicon IO" target="_blank">Wicon IO</a> </div>
</template>

<script>
export default {
name: "Footer.vue"
}
</script>

<style scoped>

</style>
