import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  catalogs: {
    stages: [],
    personal: []
  },
  stages: [],
  user: {
    profile: {}
  },
};

const mutations = {
  updateUser(state, user) {
    Vue.set(state, 'user', user);
  },
  updateCatalogs(state, catalogs) {
    Vue.set(state, 'catalogs', catalogs);
  }
};
const actions = {};

const getters = {
  catalogs: state => state.catalogs,
  user: state => state.user,
};
const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});

export default store;
