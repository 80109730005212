<template>
  <section id="wrapper" class="login-register login-sidebar"
           style="background-image:url('https://firebasestorage.googleapis.com/v0/b/wicon-mobile-development.appspot.com/o/publicAssets%2Flogin-register.jpg?alt=media&token=4cde0c50-be8e-487f-b663-091fe5ebd40c');">
    <div class="login-box card">
      <div class="card-body">
        <form class="form-horizontal form-material" id="loginform" @submit.prevent="onSubmit">
          <div class="loginLogo"><img class="loginLogo" src="../assets/images/logoTvnNoticiasLight.svg" alt="Home" /></div>

          <!-- <div class="form-group m-t-40">
            <div class="col-xs-12">
              <input class="form-control" type="email" required="" placeholder="Correo Electrónico" v-model="email" />
            </div>
          </div>
          <div class="form-group">
            <div class="col-xs-12">
              <input @focus="errorMessage = ''" class="form-control" type="password" required="" placeholder="Contraseña" v-model="password" />
            </div>
          </div> -->
          <!-- <div class="form-group">
            <div class="d-flex no-block align-items-center">
              <div class="checkbox checkbox-primary p-t-0">
                <input id="checkbox-signup" type="checkbox">
                <label for="checkbox-signup"> Recordarme </label>
              </div>
              <div class="ml-auto">
                <a href="javascript:void(0)" id="to-recover" class="text-muted"><i class="fa fa-lock m-r-5"></i> Olvide mi Contraseña?</a>
              </div>
            </div>
          </div> -->
          <!-- <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">
                <span v-if="!loading">Ingresar</span>
                <span v-if="loading">Un momento...</span>
              </button>
            </div>
          </div> -->
          <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light" type="button" @click="MSLogin()">
                <span>Ingresar</span>
              </button>
            </div>
            <div class="col-xs-12 m-t-5">
              <span class='text-muted font-10'>Version: 1.0.8</span>
            </div>

            <div class="col-xs-12 m-t-5" v-if="errorMessage">
              <div class="col-12">
                <div class="error-msg" >
                  <i class="fa fa-exclamation-circle"></i> {{ errorMessage }}
                </div>
              </div>
            </div>
          </div>
        </form>

        <form class="form-horizontal" id="recoverform" action="index.html">
          <div class="form-group ">
            <div class="col-xs-12">
              <h3>Recover Password</h3>
              <p class="text-muted">Enter your Email and instructions will be sent to you! </p>
            </div>
          </div>
          <div class="form-group ">
            <div class="col-xs-12">
              <input class="form-control" type="text" required="" placeholder="Email">
            </div>
          </div>
          <div class="form-group text-center m-t-20">
            <div class="col-xs-12">
              <button class="btn btn-primary btn-lg btn-block text-uppercase waves-effect waves-light" type="submit">
                Reset
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>

</template>

<script>
import { MSSubject } from '../MSSubject';

export default {
  name: 'Login.vue',
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      loading: false,
    }
  },
  methods: {
    async MSLogin() {
      this.errorMessage = '';

      try {

        const resp = await this.$auth.MSLogin(this.email, this.password);
        console.log('resp: ', resp)

        const resp2 = await fetch(`https://graph.microsoft.com/v1.0/users/${resp.user.toJSON().providerData[0].uid}/memberOf`, {
          headers: new Headers({
            'Authorization': 'Bearer ' + resp.credential.toJSON().oauthAccessToken,
            'Content-Type': 'application/json'
          })
        })

        const u = await resp2.json()
        const groups = u.value.map(group => group.id)
        localStorage.setItem('msToken', groups)
        MSSubject.sendMessage(groups)

      } catch (e) {
        console.error('login error: ', e);
        if (e) {
          if (e.code === 'auth/user-disabled' || e.code === 'auth/wrong-password' || e.code === 'auth/account-exists-with-different-credential' || e.code === 'auth/invalid-credential') {
            this.errorMessage = 'Valida los datos ingresados';
          }
        }

        if (!this.errorMessage) {
          this.errorMessage = 'Algo salió mal, inténtalo nuevamente';
        }

      }
    },
    async onSubmit() {
      this.errorMessage = '';
      this.loading = true;
      try {

        await this.$auth.login(this.email, this.password);

      } catch (e) {
        this.loading = false;

        if (e) {
          if (e.code === 'auth/user-disabled' || e.code === 'auth/wrong-password' || e.code === 'auth/account-exists-with-different-credential' || e.code === 'auth/invalid-credential') {
            this.errorMessage = 'Valida los datos ingresados';
          }
        }

        if (!this.errorMessage) {
          this.errorMessage = 'Algo salió mal, inténtalo nuevamente';
        }

      }
    }
  }
};
</script>

<style >
.loginLogo{
  text-align: center;
}
.loginLogo img{
  width: 70%;
}
.error-msg{
  padding-bottom: 20px;
  font-size: 15px;
  color: #ef0100;
  font-weight: 700;
}
</style>
