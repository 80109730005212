<template>
  <div class="col p-20">
    <form v-on:submit.prevent="save(operative)">
      <div class="form-body">
        <div class="row">
          <div class="col-md-6 ">
            <div class="form-group">
              <label class="control-label">Periodista *</label>
              <select
                class="form-control custom-select tvnInputTitle"
                tabindex="1"
                v-model="operative.journalist"
                required
              >
                <option></option>
                <option :value="person" v-for="person in journalists" :key="person.id">
                  {{ person.completeName || person.name + ' ' + person.lastName }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6 ">
            <div class="form-group">
              <label class="control-label">Camarógrafo *</label>

              <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="operative.cameraman"
                      required>
                <option></option>
                <option :value="person" v-for="person in cameras" :key="person.id">
                  {{ person.completeName || person.name + ' ' + person.lastName }}
                </option>
              </select>

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 ">
            <div class="form-group">
              <label class="control-label">Turno *</label>

              <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="operative.orderly">
                <option></option>
                <option :value="turn" v-for="(turn, index) in orderly" :key="index">
                  {{ turn.entrada }} - {{ turn.salida }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label class="control-label">Hora de Cobertura:</label>
              <datetime type="time" use12-hour v-model="operative.date" value-zone="America/Panama" :minute-step="30"
                        ></datetime>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-10">
            <div class="form-group">
              <label class="control-label">Detalles del Lugar (Referencia) *:</label>
              <input type="text" class="form-control" placeholder="" required v-model="operative.place" />
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group text-right">
              <div class="btn btn-outline btn-info mapButton" v-on:click="visualMap = true" v-if="!visualMap"><i
                class="fa fa-map-marker text-light"></i></div>
              <div class="btn btn-outline btn-info mapButton" v-on:click="visualMap = !visualMap" v-if="visualMap"><i
                class="fa fa-map-marker text-light"></i></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12" v-if="!visualMap || operative.directions">
            <directionmap :directions="operative.directions || {}"
                          v-on:direction-change="handleDirection"></directionmap>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Equipo Requerido</label>
              <tags-input v-model="operative.equipment"
                          element-id="tags"
                          discard-search-text=""
                          placeholder=""
                          :existing-tags="workEquipment"
                          :typeahead="true"
              ></tags-input>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="col-md-12 p-0">
              <div class="form-group">
                <label class="control-label">Vehículo</label>

                <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="operative.car">
                  <option></option>
                  <option :value="car" v-for="(car, index) in cars" :key="index">
                    {{ car.auto }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <div class="form-group">
                <label class="control-label">Romper Programación</label>
                <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="operative.cut">
                  <option></option>
                  <option :value="true">Si</option>
                  <option :value="false">No</option>
                </select>
              </div>
            </div>
            <div class="col-md-12 p-0">
              <div class="form-group">
                <label class="control-label">Información Adicional</label>
                <textarea class="form-control" placeholder="" v-model="operative.additionalInformation" rows="4"></textarea>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="pb-2">Formatos</div>
              <div v-for="(format, index) in operative.formats" :key="index">
                <input class="filled-in chk-col-light-blue" type="checkbox" :name="operative.formats[index].id" :id="operative.formats[index].id" :value="operative.formats[index].requested" v-model="operative.formats[index].requested" />
                <label :for="operative.formats[index].id">{{ format.formato }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">

        </div>
      </div>
      <div class="form-actions text-right">
        <!--        <button type="button" class="btn btn-outline btn-dark m-r-40" @click="clear()">Borrar</button>-->
        <button type="submit" class="btn btn-info text-right">
          <span v-if="!isLoading">Guardar</span>
          <span v-if="isLoading">Guardando...</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';
import DirectionMap from '@/components/DirectionMap';
import store from '@/store';
import firebase from 'firebase';
import VoerroTagsInput from '@voerro/vue-tagsinput';
import Equipment from '../assets/data/equipos.json';

export default {
  name: 'operacionalAxis',
  props: {
    stages: Array,
    operative: Object,
    id: String,
    user: Object,
    updateState: Boolean
  },
  components: {
    datetime: Datetime,
    directionmap: DirectionMap,
    'tags-input': VoerroTagsInput
  },
  data: () => {
    return {
      visualMap: true,
      isLoading: false,
      workEquipment: Equipment
    };
  },
  computed: {
    journalists() {
      return store.state.catalogs.journalist;
    },
    cameras() {
      return store.state.catalogs.cameraman;
    },
    cars() {
      return store.state.catalogs.cars;
    },
    formats() {
      return store.state.catalogs.formats;
    },
    orderly() {
      return store.state.catalogs.orderly;
    }
  },
  methods: {
    handleDirection(direction) {
      this.operative.directions = direction;
    },
    async save(operative) {

      try {
        if (!this.user.profile.role.canUpdateOperative) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });

          return;
        }
        if (this.isLoading) {
          return;
        }

        this.isLoading = true;
        const db = firebase.firestore();
        const stage = {...this.stages[1]};
        delete stage.tasks
        const updatedAt = new Date();

        if (this.updateState) {
          await db.collection('tasks').doc(this.id).set({ operative, stage, updatedAt }, { merge: true });
        } else {
          await db.collection('tasks').doc(this.id).set({ operative, updatedAt }, { merge: true });
        }

        this.$toasted.show('Guardado',
          {
            duration: 800,
            className: 'toastClass'
          });

        this.isLoading = false;
      } catch (error) {
        console.error('error: ', error);
        this.isLoading = false;
        alert('Error al guardar');
      }
    },
    clear() {
      this.operative = {};
    }
  },
  created() {
    console.log('data: ', this.operative);

    if (this.formats) {
      this.operative.formats = this.formats.map(format => {
        const fd = this.operative.formats.find(f => f.code === format.code);

        return {
          ...format,
          ...fd
        };
      });
    }
  },
  watch: {
    formats() {
      this.operative.formats = this.formats.map(format => {
        const fd = this.operative.formats.find(f => f.code === format.code);

        return {
          ...format,
          ...fd,
        };
      });
    }
  }
};
</script>
<style>
.displayCheckbox{
  position: relative !important;;
  left: 0 !important;;
  opacity: 1 !important;
}
.vdatetime .vdatetime-input {
  display: block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.mapButton {
  margin: 30px;
}

.formatos {
  height: 230px !important;
}
</style>
