<template>
  <div class="page-wrapper seccionContainer">
    <div class="container-fluid m-t-15">
      <div class="row">
        <div class="col-10">
          <h2 class="m-b-20">Detalles de Contenido</h2>
        </div>
        <div class="text-right">
          <button type="button" class="btn btn-outline-info text-right" @click="execute(task)"
                  v-if="task.stage && task.stage.id === 'development'">
            <span v-if="!isLoading"> Enviar a Ejecución</span>
            <span v-if="isLoading"> Guardando... </span>
          </button>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <div class="form-body">
            <div class="row">
              <div class="col-6">
                <h3 v-if="task && task.stage" class="box-title p-l-10">
              <span class="badge inline"
                    :class="{'badge-info':(task.stage.id == 'inception'),
                                       'badge-warning':(task.stage.id == 'development'),
                                       'badge-success':(task.stage.id == 'execution')}">
                {{ task.stage.title }}</span>
                </h3>
              </div>
              <div class="col-6 text-right">
                <button type="button" class="btn btn-secondary text-right" @click="cancel(task)">
                  <span v-if="!isLoading"> Cancelar</span>
                  <span v-if="isLoading"> Guardando... </span>
                </button>
              </div>
            </div>
            <form v-on:submit.prevent="save(task)">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group-sm">
                    <input type="text" class="form-control tvnInputTitleTop" v-model="task.title" required />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="task.department"
                            required>
                      <option></option>
                      <option :value="department" v-for="department in departments" :key="department.id">
                        {{ department.label }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group-sm">
                    <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="task.priority"
                            required>
                      <option></option>
                      <option :value="priority" v-for="priority in priorities" :key="priority.id">
                        {{ priority.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group-sm">
                    <input type="date" class="form-control tvnInputTitle" placeholder="dd/mm/yyyy"
                           v-model="task.schedule" required>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <select class="form-control custom-select tvnInputTitle" tabindex="1" v-model="task.category"
                            required>
                      <option></option>
                      <option :value="category" v-for="category in categories" :key="category.id">
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label">Etiquetas (Metadata)</label>
                    <tags-input v-model="task.tags"
                                discard-search-text=""
                                value-fields="value"
                                :add-tags-on-comma="true"
                                :typeahead="true"
                                placeholder="Editar etiquetas"
                    ></tags-input>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <!--                    <label class="control-label">Enfoque Noticioso</label>-->
                    <textarea rows="2" class="form-control tvnInputTextArea" v-model="task.description"
                              placeholder="Ingrese Enfoque Noticioso"></textarea>
                  </div>
                </div>
                <div class="col-md-4 ">
                  <div class="form-actions text-right m-t-20">
                    <button type="submit" class="btn btn-info text-right">
                      <span v-if="!isLoading"> Modificar </span>
                      <span v-if="isLoading"> Guardando... </span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <div class="card">
            <div class="card-body">
              <form v-on:submit.prevent="addQuestion(question)">
                <div class="form-body">
                  <h3 class="box-title">Pregunta</h3>
                  <hr>
                  <div class="row">
                    <div class="col-md-12 ">
                      <div class="form-group">
                        <textarea class="form-control" placeholder="Ingrese una pregunta aquí." required
                                  v-model="question.question"></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 ">
                      <div class="form-group">
                        <label class="control-label">Nombre de la Fuente:</label>
                        <input type="text" class="form-control" placeholder="" v-model="question.source" />
                      </div>
                    </div>
                    <div class="col-md-6 ">
                      <div class="form-group">
                        <label class="control-label">Teléfono:</label>
                        <input type="tel" class="form-control" placeholder="" v-model="question.phone" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Correo Electrónico:</label>
                        <input type="email" class="form-control" placeholder="" v-model="question.email" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-actions text-right">
                  <!--                  <button type="button" class="btn btn-outline btn-dark m-r-40" @click="clearQuestion()">Borrar</button>-->
                  <button type="submit" class="btn btn-info text-right">
                    <span v-if="!isLoading">Agregar Pregunta</span>
                    <span v-if="isLoading">Guardando...</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="card">
            <ul class="nav nav-tabs profile-tab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#interaccions" role="tab">Interacciones</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#questions" role="tab">Preguntas</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#operational" role="tab"
                   v-if="user && user.profile && user.profile.role && user.profile.role.canSeeOperative">Eje
                  Operativo</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#digital" role="tab">Digital</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#production" role="tab">Producción</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" id="interaccions" role="tabpanel">
                <div class="card m-b-0">
                  <div class="chat-main-box">
                    <!-- .chat-right-panel -->
                    <div class="chat-right-aside">
                      <div class="chat-main-header b-b">
                        <div class="row">
                          <div class="offset-lg-6 col-lg-6 text-right">
                            <div class="form-material p-t-10  p-b-10  p-r-20">
                              <input class="form-control p-20" type="text" placeholder="Buscar"
                                     v-model="searchInput"
                                     v-on:keyup.enter="searchComment($event.target.value)"
                                     @keyup="searchComment($event.target.value)">
                              <span v-if="searchInput">Coincidencias: {{ searchResults.length }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="chat-rbox" v-chat-scroll="{always: false, smooth: true}">
                        <ul class="chat-list p-20" id="chat-container">

                          <li v-for="(comment, index) in task.comments" :key="index"
                              :class="{'text-right':(comment.createdBy.id == user.uid)}">
                            <small class="d-block comment-date">{{ comment.createdBy.name }}
                              {{ comment.createdBy.lastName }} - {{ comment.createdAt | date }}</small>

                            <div class="" v-if="comment.attach">
                              <div
                                v-if="comment.attach.type == 'image/jpeg' || comment.attach.type == 'image/jpg' || comment.attach.type == 'image/png' || comment.attach.type == 'image/gif'">
                                <a :href="comment.attach.uri" :download="comment.text" target="_blank"> <img
                                  class="imageThumbnail" v-bind:src="comment.attach.uri"></a>
                              </div>
                              <div v-else class="fileIcon">
                                <a :href="comment.attach.uri" :download="comment.text" target="_blank">
                                  <i class="fa-3x far fa-file-alt text-grey fileIconLink"></i>
                                </a>
                              </div>
                              <span class="comment-file"
                                    v-bind:class="{ 'search-result': searchResults.includes(comment),
                                   'comment-own-file':(comment.createdBy.id == user.uid),  }"
                              > {{ comment.text }}
                              </span>

                            </div>

                            <div v-if="!comment.attach">
                              <i class="far fa-clone cloneIcon" v-clipboard:copy=comment.text
                                 @click="cAnimation = !cAnimation; autoHide();"></i>
                              <span class="comment-main-text"
                                    v-bind:class="{ 'search-result': searchResults.includes(comment),
                                   'comment-own':(comment.createdBy.id == user.uid)
                                      }"
                              >{{ comment.text }}
                            </span>
                            </div>

                            <div class="m-t-5" v-if="!comment.attach">

                              <i class="far fa-heart text-danger pointerCursor"
                                 v-if="!comment.likes || comment.likes.length == 0"
                                 @click="addCommentLike(index)"></i>
                              <i class="fa fa-heart text-danger pointerCursor"
                                 v-if="comment.likes && comment.likes.length >= 1"
                                 @click="addCommentLike(index)"></i>

                              <!--  <small v-if="comment.likes"> {{ comment.likes.length }} Likes</small>-->
                              <span class="likedBy small" v-for="(like, index) in comment.likes" :key="index">
                                {{ like.name }} {{ like.lastName }},
                              </span>
                            </div>
                          </li>

                          <span class="cloneAnimationClass" v-if="cAnimation">Copiado al Portapapels <i
                            class="fa fa-check"></i></span>

                          <!--chat Row -->
                        </ul>
                      </div>
                      <div class="card-body b-t">
                        <div class="row">
                          <div class="col-1">
                            <input id="fileUpload" type="file" hidden @change="processFiles">
                            <button type="button" class="btn btn-light btn-circle btn-lg text-center"
                                    accept="image/jpeg, image/gif, image/png, .doc, .docx, .txt, .xlsx, .xls, .pdf, .csv, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    @click="chooseFiles()">
                              <i class="fas fa-paperclip"></i>
                            </button>
                          </div>
                          <div class="col-9">
                            <textarea placeholder="Escribe tu comentario aquí" class="form-control b-0 chat-textarea"
                                      v-model="comment"></textarea>
                          </div>
                          <div class="col-2 text-right">
                            <button type="button" class="btn btn-info btn-circle btn-lg" @click="addComment(comment)"
                                    v-on:keyup.enter="addComment(comment)">
                              <i v-if="!isLoading" class="fas fa-paper-plane"></i>
                              <span v-if="isLoading"><i class="fa fa-cloud-upload-alt"></i></span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- .chat-right-panel -->
                  </div>
                  <!-- /.chat-row -->
                </div>
              </div>

              <div class="tab-pane" id="questions" role="tabpanel">
                <div class="col-12 col-sm-12 p-20">
                  <div class="list-group">
                    <div class="" v-if="!task.questions || !task.questions.length">
                      No existen preguntas al momento.
                    </div>
                    <div
                      class="list-group-item list-group-item-action flex-column align-items-start active pregutasContent"
                      v-for="(question, index) in task.questions" :key="index">
                      <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1 text-white">{{ getDayTitle(question.createdAt) }}</h6>
                      </div>
                      <h4 class="mb-1 text-light">{{ question.question }}</h4>
                      <span>Fuente: <span class="text-capitalize">{{ question.source }}</span> - {{ question.phone
                        }}</span><br>
                      <span>Correo Electrónico: {{ question.email }}</span>

                      <div @click="cAnimation = !cAnimation; autoHide();">

                      </div>

                      <button type="button" class="btn btn-outline-info" @click="deleteQuestion(index)">
                        <i class="fa fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="tab-pane" id="operational" role="tabpanel" v-if="task && task.stage">
                <OperationalAxis
                  :id="id"
                  :operative="task.operative || {formats:[]}"
                  :stages="stages || []"
                  :user="user"
                  :updateState="task.stage.id === 'inception'"
                >
                </OperationalAxis>
              </div>

              <div class="tab-pane" id="sport" role="tabpanel" v-if="task.department.id === 'sports'">
                <SportAxis :id="id" :user="user" :sports="task.sports || {}"></SportAxis>
              </div>

              <div class="tab-pane" id="digital" role="tabpanel" v-if="task.digital">
                <Digital :id="id" :user="user" :digital="task.digital"></Digital>
              </div>

              <div class="tab-pane" id="production" role="tabpanel" v-if="task.production">
                <Production :id="id" :user="user" :production="task.production"></Production>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import store from '../store';
import firebase from 'firebase';
import dayjs from 'dayjs';
import OperationalAxis from '../components/OperationalAxis';
import SportAxis from '@/components/SportAxis';
import Production from '@/components/Production';
import Digital from '@/components/Digital';
import VoerroTagsInput from '@voerro/vue-tagsinput';
import router from '@/router';

const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export default {
  name: 'Edit',
  components: {
    Digital,
    OperationalAxis,
    SportAxis,
    'tags-input': VoerroTagsInput,
    Production
  },
  data: () => {
    return {
      comment: '',
      currentAttach: null,
      id: '',
      isLoading: false,
      question: {
        email: '',
        phone: '',
        question: '',
        source: '',
        createdAt: null,
        completed: false
      },
      searchInput: '',
      searchResults: [],
      task: {
        tags: [],
        category: null,
        description: '',
        priority: null,
        department: {},
        title: ''
      },
      cAnimation: true

    };
  },
  methods: {
    autoHide() {
      if (this.cAnimation === true) {
        setTimeout(() => this.cAnimation = false, 1000);
      }
    },
    clearQuestion() {
      this.question = {
        email: '',
        phone: '',
        question: '',
        source: '',
        completed: false
      };
    },
    clearTask() {
      this.id = '';
      this.task = {
        category: null,
        description: '',
        priority: null,
        schedule: new Date(),
        title: ''
      };
    },
    chooseFiles() {
      document.getElementById('fileUpload').click();
    },
    getDayTitle(date) {
      const createdAt = dayjs(date);
      const today = dayjs(new Date());
      const days = today.diff(createdAt, 'day');

      if (days === 1) {
        return 'Hace 1 día';
      } else if (days > 1) {
        return `Hace ${days} días`;
      } else {
        return 'Hoy';
      }
    },
    getTask() {

      try {
        const db = firebase.firestore();
        db.collection('tasks').doc(this.$route.params.id).onSnapshot((taskRef) => {
          this.task = taskRef.data();

          if (this.task.tags) {
            this.task.tags = this.task.tags.map(tag => {
              return { key: '', value: tag };
            });
          }
          console.log('this.task: ', this.task);
          this.task.schedule = dayjs(this.task.schedule.toDate()).format('YYYY-MM-DD');
          const container = this.$el.querySelector('#chat-container');

          if (container) {
            container.scrollTop = container.scrollHeight;
          }

        });
      } catch (error) {
        alert('No fue posible cargar la página');
      }
    },
    processFiles(event) {
      this.currentAttach = event.target.files[0];
      this.comment = this.currentAttach.name;
    },
    searchComment(input) {

      if (input) {
        const inputL = input.toLowerCase();
        this.searchResults = this.task.comments.filter(comment => comment.text.toLowerCase().includes(inputL));
      } else {
        this.searchResults = [];
      }
    },
    async upload() {
      this.img1 = null;
      const storageRef = firebase.storage().ref(`${this.id}/${this.currentAttach.name}`);

      await storageRef.put(this.currentAttach);

      const uri = await storageRef.getDownloadURL();
      const type = this.currentAttach.type;
      const name = this.currentAttach.name;

      this.currentAttach = null;
      return { uri, type, name };
    },
    async addComment(comment) {
      try {

        if (!this.isLoading) {

          this.isLoading = true;
          const db = firebase.firestore();
          const commentL = {
            text: comment,
            createdAt: new Date(),
            likes: [],
            createdBy: { ...this.user.profile, id: this.user.uid }
          };

          let comments = this.task.comments;


          if (this.currentAttach) {
            commentL.attach = await this.upload();
          }

          if (comments) {
            comments.push(commentL);
          } else {
            comments = [commentL];
          }

          if (this.id) {
            await db.collection('tasks').doc(this.id).set({ comments }, { merge: true });
            this.comment = '';
            this.task.comments = comments;
          }

          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        alert('Error al guardar');
      }
    },
    async addCommentLike(index) {
      try {

        const db = firebase.firestore();
        const like = { ...this.user.profile, id: this.user.uid, createdAt: new Date() };

        if (this.task.comments[index].likes) {
          const i = this.task.comments[index].likes.findIndex(like => like.id === this.user.uid);

          if (i > -1) {
            this.task.comments[index].likes.splice(i, 1);
          } else {
            this.task.comments[index].likes.push(like);
          }
        } else {
          this.task.comments[index].likes = [like];
        }

        if (this.id) {
          await db.collection('tasks').doc(this.id).set({ comments: this.task.comments }, { merge: true });
        }

      } catch (error) {
        alert('Error al guardar');
      }
    },
    async addQuestion(question) {

      if (!this.user.profile.role.canAddQuestions) {
        this.$toasted.show('No tienes permisos para hacer esto', {
          duration: 800,
          className: 'toastClass'
        });

        return;
      }

      const questionsBk = this.task.questions;
      try {
        if (!this.isLoading) {
          this.isLoading = true;
          question = {
            ...question,
            completed: false,
            createdAt: new Date(),
            createdBy: { ...this.user.profile, id: this.user.uid }
          };

          let questions = this.task.questions;
          if (questions) {
            questions.push(question);
          } else {
            questions = [question];
          }
          if (this.id) {
            this.task.questions = questions;
            this.isLoading = false;
            await this.save(this.task);
            this.clearQuestion();
          }
          this.isLoading = false;
        }
      } catch (error) {
        this.task.questions = questionsBk;
        this.isLoading = false;
        alert('Error al guardar');
      }

    },
    async deleteQuestion(i) {
      if (!this.user.profile.role.canDeleteQuestions) {
        this.$toasted.show('No tienes permisos para hacer esto', {
          duration: 800,
          className: 'toastClass'
        });

        return;
      }
      const questionsBk = this.task.questions;
      try {
        if (!this.isLoading) {

          this.isLoading = true;
          this.task.questions.splice(i, 1);
          this.isLoading = false;

          await this.save(this.task);
        }
      } catch (error) {
        this.task.questions = questionsBk;
        this.isLoading = false;
        alert('Error al guardar');
      }
    },
    async cancel(task) {
      try {
        if (!this.user.profile.role.canCancel) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });
          return;
        }

        if (this.isLoading) {
          return;
        }
        this.isLoading = true;

        task.stage = this.stages.find(stage => stage.id === 'cancel');
        task.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

        const db = firebase.firestore();
        const taskRef = db.collection('tasks').doc(this.id);
        await taskRef.set({ stage: task.stage }, { merge: true });

        this.isLoading = false;
        this.task = task;
        this.$toasted.show('Esta tarea fue cancelada',
          {
            duration: 800,
            className: 'toastClass'
          });

        await router.replace('/home');
      } catch (error) {
        this.isLoading = false;
        alert('Hubo un error al guardar');
      }
    },
    async execute(task) {
      try {
        if (!this.user.profile.role.canExecute) {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });
          return;
        }

        if (this.isLoading) {
          return;
        }
        this.isLoading = true;

        task.stage = this.stages[2];
        task.updatedAt = firebase.firestore.FieldValue.serverTimestamp();

        const db = firebase.firestore();
        const taskRef = db.collection('tasks').doc(this.id);
        await taskRef.set({ stage: task.stage }, { merge: true });

        this.isLoading = false;
        this.task = task;
        this.$toasted.show('Enviado a Ejecución',
          {
            duration: 800,
            className: 'toastClass'
          });


      } catch (error) {
        console.error('error: ', error)
        this.isLoading = false;
        alert('Hubo un error al guardar');
      }
    },
    async save(task) {
      try {

        if (this.isLoading) {
          return;
        }

        if (this.user.profile.role.canUpdate) {
          this.isLoading = true;

          task.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
          task.updatedBy = { ...this.user.profile, id: this.user.uid };

          if (!task.stage || task.stage === this.stages[0]) {
            task.stage = (task.operative && task.operative.journalist) ? this.stages[1] : this.stages[0];
          }

          if (this.task.tags && this.task.tags.length) {
            task.tags = this.task.tags.map(tag => tag.value);
          }

          task.schedule = dayjs(task.schedule, 'YYYY-MM-DD').toDate();

          const db = firebase.firestore();

          const taskRef = db.collection('tasks').doc(this.id);
          await taskRef.set({ ...task }, { merge: true });

          this.isLoading = false;
          this.$toasted.show('Guardado', {
            duration: 800,
            className: 'toastClass'
          });
        } else {
          this.$toasted.show('No tienes permisos para hacer esto', {
            duration: 800,
            className: 'toastClass'
          });
        }


      } catch (error) {
        // console.error('error: ', error);
        this.isLoading = false;
        alert('Hubo un error al guardar');
      }
    }
  },
  computed: {
    user() {
      return store.state.user;
    },
    stages() {
      return store.state.catalogs.stages.sort((a, b) => (a.order > b.order) ? 1 : -1);
    },
    categories() {
      return store.state.catalogs.categories;
    },
    departments() {
      // console.log('store.state.catalogs.departments: ', store.state.catalogs.department);
      return store.state.catalogs.department;
    },
    priorities() {
      return store.state.catalogs.priorities;
    }
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getTask();
  }
};
</script>
<style scoped>
.tvnInputTitle {
  border: none;
}

.tvnInputTitle:focus {
  box-shadow: 0 3px #c6d9f7;
  font-weight: bold;
}

.tvnInputTitleTop {
  border: none;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.tvnInputTitleTop:focus {
  box-shadow: 0 3px #c6d9f7;
  font-weight: bold;
}

.tvnInputTextArea {
  border: solid 1px #5ea2d940;
  border-bottom: 2px solid #1e88e5;
  font-size: 1rem;
}

.tvnInputTextArea:focus {
  box-shadow: 0 3px #c6d9f7;
}

.pregutasContent {
  margin-bottom: 5px;
  border-radius: 5px;
}

.chat-rbox {
  max-height: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
}

.pointerCursor {
  cursor: pointer;
}

.comment-main-text {
  background: #bedbf3;
  padding: 5px 20px;
  border-radius: 5px;
  color: black;
}

.chat-textarea {
  background-color: #f9f9f9;
  border: solid .5px #e4e4e4;
}

.comment-own {
  background: #e9a54f !important;
  color: white;
}

.comment-own-file {
  background: #e9a54f !important;
  color: #ffffff;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
}

.comment-file {
  font-size: 12px;
}

.fileIcon {
  padding: 0 0 5px 0;
  border-radius: 5px;
  font-size: 18px;
}

.fileIconLink {
  color: #1d1d1d !important;
}

.comment-date {
  font-size: 70%;
  padding: 5px 0px;
  border-radius: 5px;
  color: #585858;
}

.imageThumbnail {
  width: 20%;
  border-radius: 5px;
  margin-bottom: 3px;
}

.cloneIcon {
  font-size: 90%;
  cursor: pointer;
  color: #bababa;
  margin-right: 8px;
}

.cloneIcon:hover {
  color: #21486b;
}

.cloneAnimationClass {
  font-size: 12px;
  font-weight: bold;
  color: #102e5a;
  position: absolute;
}

.cloneAnimationClass {
  animation: fadeOutAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.search-result {
  background-color: #be5b5b !important;
}

@keyframes fadeOutAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

</style>
